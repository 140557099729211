<template>
  <FlatPickr
    :modelValue="props.filter.value"
    :class="['flatpickr-vue', 'w-full', 'form-control']"
    :config="config"
    @update:modelValue="onUpdateModelValue"
  />
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import dayjs from "dayjs";
  import FlatPickr from "vue-flatpickr-component";
  import { ColumnFilterProps, ColumnFilterEmits } from "../../lib/interfaces";

  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();

  const onUpdateModelValue = (newData: string | null): void => {
    if (!newData) return;
    const updatedData = dayjs(newData).toISOString();

    emit("update:filter", {
      filterName: props.column.name,
      data: updatedData,
    });
  };

  const config = ref<Partial<import("flatpickr/dist/types/options").BaseOptions>>({
    defaultHour: 0,
    defaultMinute: 0,
    enableTime: true,
    disableMobile: false,
    mode: "single", //
  });
</script>

<style scoped></style>
